






import Vue from 'vue'
import Component from 'vue-class-component'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import tinymce from 'tinymce/tinymce'
import TinyMceEditor from '@tinymce/tinymce-vue'

// #region TinyMCE
import 'tinymce/themes/silver'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/image'
import 'tinymce/plugins/lists'
import { request } from '@/edshed-common/api'

tinymce.baseURL = window.location.origin + '/tinymce'

Vue.component('tinymce-editor', TinyMceEditor)
// #endregion

@Component({ components: {}, props: ['value'] })
export default class EmailRichTextField extends Vue {
  private instance = this

  private handler (blobInfo, success, failure) {
    if (blobInfo === null) {
      return
    }

    const regex = /image\/(.*)/

    let m
    let fileType

    if ((m = regex.exec(blobInfo.blob().type)) !== null) {
      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        if (groupIndex === 1) {
          fileType = match

          request('POST', 'email/image', { image: { data: ';base64,' + blobInfo.base64(), extname: '.' + fileType } })
            .then((response) => {
              if (!response || !response.data) {
                return failure('An error occurred')
              }

              const images = response.data.path.split(';')
              if (images.length < 1) {
                return failure('An error occurred')
              }

              success('https://files.edshed.com/' + images[0])
            })
            .catch((error) => {
              console.log(error)
              failure()

              if (error.response.status === 403) {
                console.log('FORBIDDEN')
                this.$router.push('/logout')
              }
            })
        }
      })
    }
  }

  private tinyConfig = {
    selector: '#tiny',
    min_height: 350,
    menubar: false,
    toolbar: 'code | undo redo | styleselect | bold italic underline strikethrough | insertName | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | table | link image',
    plugins: 'paste link fullscreen code table media image lists autoresize',
    content_css: window.location.origin + '/tinymce/tinymce.css',
    setup: (editor) => {
      editor.ui.registry.addButton('insertName', {
        tooltip: 'Insert Name',
        onAction: () => editor.insertContent('%recipient.name%'),
        icon: 'user'
      })
    },
    height: 300,
    images_upload_handler: this.handler.bind(this.instance)
  }

  private text: string = ''

  // private scrollPreventer = (e: Event) => e.preventDefault()

  public mounted () {
    const func = (blobInfo, success, failure) => {
      console.log(this)
      if (blobInfo === null) {
        return
      }

      const regex = /image\/(.*)/

      let m
      let fileType

      if ((m = regex.exec(blobInfo.blob().type)) !== null) {
        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            fileType = match

            console.log(this)

            request('POST', 'email', { data: blobInfo.base64(), extName: '.' + fileType })
              .then((response) => {
                success()
              })
              .catch((error) => {
                console.log(error)
                failure()

                if (error.response.status === 403) {
                  console.log('FORBIDDEN')
                  this.$router.push('/logout')
                }
              })
          }
        })
      }
    }
    this.tinyConfig.images_upload_handler = this.tinyConfig.images_upload_handler.bind(this)
    this.tinyConfig.plugins = 'paste link fullscreen code table media'
    console.log('binding')

    // TODO: Needed to fix TinyMCE context menus from appearing in the wrong place...
    window.scrollTo({ top: 0 })

    // window.addEventListener('wheel', this.scrollPreventer)

    this.$watch('value', (text) => {
      this.text = text
    })

    this.$watch('text', (text) => {
      console.log(text)

      this.$emit('input', text)
    })
  }

  public destroyed () {
    // window.removeEventListener('wheel', this.scrollPreventer)
  }

  public data () {
    return {
      text: this.$props.value
    }
  }
}
